import React from 'react'

import ClientList from './Page/Components/ClientList'
import Text from './Page/Components/Text'
interface PageProps {
  page: Uto.PageData
}

const getImage = (images: any) => {

  const defaultImage = { url: "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80" }
  return images !== undefined ? images[0].thumbnails.large : defaultImage

}

const Page = ({ page }: PageProps) => {
  const { menu_title, description, slogan, Attachments } = page
  const img = getImage(Attachments)

  return (
    <>
      <div className="relative bg-gray-200">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img className="h-56 w-full object-cover lg:absolute lg:h-full" src={img.url} alt="" />
          </div>
        </div>
        <div className="h-full h-screen relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0 flex flex-col">
              <div>
                <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">{slogan}</h2>
                <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{menu_title}</h3>
                <Text text={description} tailwind='text-xl mt-8' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientList />
    </>
  )

}

export default Page
import env from "react-dotenv";
import Airtable from 'airtable'

const API_KEY = env.AIRTABLE_API_KEY || ""
const API_DB = env.AIRTABLE_DB || ""

const database = new Airtable({ apiKey: API_KEY }).base(API_DB)

const pagesFetch = database('Pages')
const services = database('services')
const clientList = database('SiteMeta')
const caseStudies = database('caseStudies')

export const getPages = async () => {
  try {
    const pages = await pagesFetch.select().firstPage()
    return pages
  } catch (err) {
    console.log(err)
  }
}

export const getSiteMetaData = async () => {
  try {
    const siteMetaData = await clientList.select().firstPage()
    return siteMetaData
  } catch (err) {
    console.log('--- ** ---')
    console.log(err)
  }
}

export const getClientLogoList = async (id: string) => {
  const page = await clientList.find(id)
  return page
}

export const getPage = async (pageName: string) => {
  const page = await pagesFetch.find(pageName)
  return page
}

export const getServices = async () => {
  try {
    const ourservices = await services.select().firstPage()
    return ourservices
  } catch (err) {
    console.log(err)
  }
}

export const getCaseStudies = async () => {
  try {
    const casestudies = await caseStudies.select().firstPage()
    const records = simplifyRecords(casestudies)

    return records.map(r => simplifyCaseStudy(r))

  } catch (err) {
    console.log(err)
  }
}

export const simplifyCaseStudy = (record: any) => {

  return {
    imgUrl: defaultImageUrl(record),
    logoUrl: defaultImageUrl(record, "logo"),
    title: record.Title,
    ...record
  }
}

export const simplifyPageRecord = (record: Airtable.Record<any>): Uto.PageData => {
  return {
    id: record.id,
    ...record.fields
  }
}

export const defaultImageUrl = (record: Airtable.Record<any>, key: string = "Attachments"): string => {
  const attachment = Object.getOwnPropertyDescriptor(record, key)
  return attachment?.value?.map((k: any) => k.thumbnails?.large.url)
}

export const simplifyRecords = (records: Airtable.Record<any>[]) => {
  return records.map(r => simplifyRecord(r))
}

export const simplifyRecord = (record: Airtable.Record<any>) => {
  return {
    id: record.id,
    ...record.fields
  }
}

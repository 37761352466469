import env from "react-dotenv";

import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import { getPages, simplifyPageRecord, simplifyRecord, getServices, getCaseStudies } from './airtableApi'

import Page from './Page'
import Home from './Page/Home'
import Nav from './Page/Components/Navigation'
import Footer from './Page/Footer'
import CaseStudies from "./Page/CaseStudies";

import './App.css';

function App() {
  const [pages, setPages] = useState<any>([])
  const [home, setHome] = useState<any>({})
  const [allServices, setServices] = useState<any>([])
  const [studies, setClients] = useState<any>([])

  useEffect(() => {
    getPages()
      .then(response => {
        const allPages = response?.map(record => simplifyPageRecord(record))
        const homePage = allPages?.find(page => page.url_key === "home")

        const showPages = env.LOCAL_DEV === 'MACHINE' ? allPages : allPages?.filter(i => i.Status === "Active")

        setHome(homePage)
        setPages(showPages)
      })
  }, [])


  useEffect(() => {
    getServices()
      .then(response => {
        const allServices = response?.map(record => simplifyRecord(record))
        setServices(allServices)
      })
  }, [])


  useEffect(() => {
    getCaseStudies().then(resp => {
      setClients(resp)
    })
  }, [])


  useEffect(() => {
    import('./Page/CaseStudies')
  }, [])

  return (
      <div className="flex flex-col w-screen">
        <Nav pages={pages} />
        <Switch>
          <Route key="home-page" exact={true} path="/" component={() => <Home services={allServices} {...home} />} />
          <Route key="case-study" exact={true} path="/case-studies" component={() => <CaseStudies studies={studies} />} />
          {
            pages.map((page: any) => <Route key={page.url_key} exact={true} path={`/${page.url_key}`} component={() => <Page page={page} />} />)
          }
        </Switch>
        <Footer pages={pages} />
      </div>
  );
}

export default App;

import React from 'react'

import Section from './Section'
import Slogan from './Slogan'
import ServiceItems from './ServiceItems'

const Home = ({slogan, description, services}: {slogan:string, description: string, services: Uto.ServiceItem[]} ) => {
  const homeTitle="We are a Boutique Agency. Sharp & Nimble. We move fast."

    return <div className="flex flex-col">
      <Slogan title={slogan} content={description}/>
      <Section title={homeTitle}>
        <>
          {services.map((s: any) =>  (<h3 className="text-2xl font-bold text-white">{s.title}</h3>) )}
        </>
      </Section>
      <ServiceItems services={services}/>
    </div>

}

export default Home
import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.png'
import mobileMenu from '../../assets/svg/mobile-menu.svg'

const Navigation = memo(({ pages }: {pages: Uto.PageData[]}) => {
  const [navMenu, setNavMenu] = useState<any>(["hidden", ""])

  const [toggleDropdownList, toggleMobileMenu] = navMenu

  return (
    <div className="sticky z-10 top-0 flex justify-between bg-gray-700">
      <div className="p-2">
        <Link to="/">
          <img className="pl-10 h-10" src={logo} alt="Uto Web design services, Fiji" />
        </Link>
      </div>
      <div className="flex-col hidden sm:inline-flex sm:flex-row">
        {
          pages.filter((page: any) => page.url_key !== 'home').map((page: any) =>
            <div className="p-3 sm:px-5" key={page.url_key}>
              <Link
                className="text-gray-200 font-bold pr-5 uppercase sm:normal-case"
                to={`/${page.url_key}`}>{`${page.menu_title}`}
              </Link>
            </div>
          )
        }
      </div>

      <div className="sm:hidden">
        <div>
          <a className={`${toggleMobileMenu} relative  text-indigo-500 text-xl`} href="#" onClick={() => {setNavMenu(['', "hidden"]) }}>
            <img className="bg-gray-200 shadow rounded m-3 w-12 h-10" src={mobileMenu} alt="Menu"/>
          </a>
        </div>
      </div>
      <div className={`${toggleDropdownList} w-1/2 flex-col origin-top-right absolute mt-5 mr-5 right-0 z-20 bg-white rounded-md shadow-lg `}>
        {
          pages.filter((page: any) => page.url_key !== 'home').map((page: any) =>
            <div className="p-3 sm:px-5" key={page.url_key}>
              <Link
                onClick={() => setNavMenu(['hidden', ''])}
                className="text-indigo-700 font-bold pr-5 uppercase sm:normal-case"
                to={`/${page.url_key}`}>{`${page.menu_title}`}
              </Link>
            </div>
          )
        }
      </div>
    </div>
  )
})

export default Navigation
import React from 'react'

import check from '../assets/svg/check.svg'

const ServiceItem = ({ service }: { service: Uto.ServiceItem }) => {
  const { title, description } = service
  return (
    <div className="flex">
      <img src={check} alt="check" className="flex-shrink-0 h-6 w-6 text-green-500"/>
      <div className="ml-3 p-2">
        <dt className="text-lg leading-6 font-medium text-gray-900">
          {title}
        </dt>
        <dd className="mt-2 text-base text-gray-500">
          {description}
        </dd>
      </div>
    </div>)
}

const ServiceListings = ({ services }: { services: Uto.ServiceItem[] }) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">We are All-in-one Design Agency</h2>
          <p className="mt-4 text-lg text-gray-500">If you can dream it up. We can help you lay it out and develop it.</p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {services.map((service: Uto.ServiceItem) => <ServiceItem service={service} />)}
        </dl>
      </div>
    </div>
  )
}

export default ServiceListings
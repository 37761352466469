import React, { useEffect, useState } from 'react'
import { getClientLogoList, simplifyRecord } from '../../airtableApi'

const clientData = "recLKmih8ZEKmIvL5"

const ClientList = () => {
  const [logos, setLogos] = useState<any>([])

  useEffect(() => {
    getClientLogoList(clientData)
      .then(resp => {

        const record = simplifyRecord(resp)
        const thumbnails = record?.Attachments.map((logo: any) => ({ id: logo.id, urlPath: logo.thumbnails.large.url }))

        setLogos(thumbnails)
      })
  }, [])

  const styles = { filter: 'grayscale(1)', opacity: 0.8, backgroundBlendMode: 'multiply' }

  return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
            We have been trusted to provide services for both Local and International Organisations.
          </p>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
            {
              logos.map(({ urlPath, id }: { id: string, urlPath: string }) => <div key={id} className="col-span-1 flex justify-center py-8 px-8 bg-gray-50" style={styles}>
                <img className="max-h-12" src={urlPath} alt="" />
              </div>)
            }
          </div>
        </div>
      </div>)
}

export default ClientList
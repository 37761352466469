import React from 'react'
import displays from '../assets/svg/displays.svg'

const Slogan = ({title, content}: {title: string, content?: string}) => {
  return(
    <>
    <div className="bg-gray-100 py-16 flex flex-col sm:flex-row">
      <div className="flex-1 text-right p-16">
        <h1 className="text-6xl font-extrabold text-blue-800">{title}</h1>
      </div>
      <div className="flex-1 p-16">
        <img src={displays} alt="Displays"/>
      </div>
    </div>
    <div className="text-center border-b-8 border-purple-700">
      <p className="p-8 text-2xl text-blue-500 font-bold">{content}</p>
    </div>
    </>
  ) 
}

export default Slogan
import React, { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'

import { getSiteMetaData, simplifyRecord } from '../../airtableApi'

const Gallery = () => {
  const [gallery, setGallery] = useState<any>(false)

  useEffect(() => {
    getSiteMetaData()
      .then(response => {
        const results = response?.map(record => simplifyRecord(record))
        const result = results?.find(item => item.name === "Graphic-Design")

        const photos = result?.Attachments?.map((photo: any) => {
          return {
            original: photo.thumbnails.large.url,
            thumbnail: photo.thumbnails.small.url
          }
        }
        )
        setGallery(photos)
      })
  }, [])

  if (!gallery) {
    return <h4> Loading Gallery ...</h4>
  }

  return <ImageGallery items={gallery} />
}

export default Gallery

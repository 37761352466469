import React, { useState, useEffect, memo } from 'react'

import Text from '../Page/Components/Text'
import Gallery from '../Page/Components/GraphicImageGallery'
const Study = (data: Uto.StudyItem) => {

  const [cssTag, setTags] = useState<string | undefined>("string")
  const { studyTitle, title, cssTags, content, serviceTags, imgUrl, clientUrl } = data

  useEffect(() => {
    setTags(cssTags)
  }, [cssTags])

  return (
    <div className={`${cssTag} overflow-hidden`}>
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
        <div className="mx-auto text-base max-w-prose lg:max-w-none">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Case Study</h2>
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{title}</h3>
          <h5 className="mt-3 text-xl text-gray-700">{studyTitle}</h5>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  {!!imgUrl ? <img className="rounded-lg shadow-lg object-cover object-center" src={imgUrl} alt={title} width="1184" height="1376" /> : null}
                </div>
              </figure>
              <div className="flex">
                {serviceTags.map(service => <div className="rounded-full py-2 px-2 m-2 bg-indigo-300 text-gray-700 w-auto text-sm">{service}</div>)}
              </div>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <Text text={content} />
            </div>
            <div className={`mt-5 prose prose-indigo mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 ${cssTags}`}>
              {/* <p>{content}</p> */}
              <span className="block mt-5">
                <a target="_blank" rel="noreferrer" className="bg-green-600 text-white font-bold shadow-md py-2 px-4 rounded" href={clientUrl}>Visit Client site</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>)
}

const CaseStudies = memo(({studies}: {studies: Uto.StudyItem[]}) => {
  return (<>
    {studies.map((s: Uto.StudyItem) => <Study key={s.id} {...s} />)}
    {/* <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8"></div> */}
    <div className="flex flex-col md:flex-row">
      <div className="flex sm:w-screen bg-gray-300">
        <div className="relative p-8 md:py-16 md:left-1/4 text-indigo-900">
          <h3 className="py-3 text-3xl font-bold">Graphic / Print Design.</h3>
          <ul className="text-xl">
            <li>Logos</li>
            <li>Business Cards</li>
            <li>Letter Heads</li>
            <li>Brochures</li>
            <li>Full Page News paper advertisments</li>
          </ul>
          <p className="pt-8 text-xl font-bold text-gray-800">We have designers with years of experience.
          <span className="mt-4 block text-2xl text-green-700">Contact us for your next project</span></p>
        </div>
      </div>
      <div className="p-5 inline-block lg:px-10 lg:w-1/2 bg-gray-200">
         <Gallery />
      </div>
    </div>
  </>
  )
})

export default CaseStudies
import React from 'react'
import svg from '../assets/svg/section-bg.svg'

const styles = {
  backgroundImage: `url(${svg})`,
  backgroundPosition: 'left',
  backgroundSize: 'cover'
}

const Section = ({title, children}: {title?: string, children: any}) => {
  return(
    <div className="h-auto flex flex-col md:flex-row content-center bg-gradient-to-r from-blue-400 to-purple-900"  >
      <div className="p-4 md:p-16 flex-1" style={styles}>
        <h3 className="p-4 md:p-8 font-extrabold text-gray-400 text-6xl">{title}</h3>
      </div>
      <div className="p-16 flex-1 content-center">
        <div className="p-8">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Section